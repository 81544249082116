import { ActionType, createReducer, createStandardAction } from 'typesafe-actions';
import { createStructuredSelector } from 'reselect';

export type AppSettingsLocalData = {
  shareXPoint:number|string,
 
};

const initialState: AppSettingsLocalData = {
  shareXPoint:'--',
};

export type Selectors = {
  getData: AppSettingsLocalData;
};

const actions = {
  update: createStandardAction('UPDATE_APP_SETTINGS_CACHE_DATA')<
    AppSettingsLocalData
  >(),
  updateShareXPoint: createStandardAction('UPDATE_APP_SHARE_X_POINT_SETTINGS_CACHE_DATA')<
  number|string
  >(),

  reset: createStandardAction('RESET_APP_SETTINGS_CACHE_DATA')()
};

export type PersistSettingsDataActions = ActionType<typeof actions>;

const reducer = () =>
  createReducer<AppSettingsLocalData, PersistSettingsDataActions>(
    initialState
  )
    .handleAction(actions.update, (state, { payload }) => ({
      ...state,
      ...payload
    }))
    .handleAction(actions.updateShareXPoint, (state, { payload }) => ({
      ...state,
      shareXPoint:payload
    }))
    .handleAction(actions.reset, () => ({ ...initialState }

    ));

const select = createStructuredSelector<AppSettingsLocalData, {}, Selectors>({
  getData: state => state,
});

export { actions, reducer, select };
