import { useSelector } from "react-redux";
import { AppState } from "../redux/app-state";
import { appSettingLocalData } from "../redux/local";
import { sharedSelectors } from "../redux";

export const useAppSetting=()=>{

    const appSettingCacheData = useSelector<AppState, appSettingLocalData.Selectors>(
        ({ shared }) =>
          sharedSelectors.local.appSettingLocalData(
            shared.local.appSettingLocalData,
            {}
          )
      );

      return{
        shareXPoint:  appSettingCacheData?.getData?.shareXPoint
      }
}