//epoch-events/epoch-events
export const epoch_events = "epoch-events/epoch-events"
export const getTopevents = 'epoch-events/getTopevents'
export const getEventList = 'epoch-events'
export const sendTask = '/epoch-events/epoch-task'
export const getTask = '/epoch-events/epoch-task-status'
export const updateTask = '/epoch-events'

export const getWebsiteTaskList = 'epoch-events/getWebsiteTaskList'

export const getSharePoint = 'epoch-events/getShareStore'