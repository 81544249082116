// export const spliceGPTDescriptions = (input: string): string[] => {
//   const matchLength = 3;
//   // const regex = /"""(.*?)"""/gs; // 捕获在 """ 和 """ 之间的内容
//   let regex = /"(.*?)"/gs; // 捕获在 "" 之间的内容
//   let matches = input.match(regex);

//   if (matches && matches.length === matchLength) {
//     return matches.map((match) => match.slice(1, -1)); // 去掉最外层的 """
//   }


//    regex = /"(.*?)"/gs; // 捕获在 "" 之间的内容
//    matches = input.match(regex);

//   if (matches && matches.length === matchLength) {
//     return matches.map((match) => match.slice(1, -1)); // 去掉最外层的 """
//   }

//   //如果没有，尝试第二次
//   console.log("2");
//   regex = /\*\*(.*?)\n\n/gs; // 捕获在 ** 和 \n\n 之间的内容
//   matches = input.match(regex);

//   if (matches && matches.length === matchLength) {
//     return matches.map((match2) => match2.slice(2, -4)); // 去掉最外层的 """
//   }

//   //如果没有，尝试第3次
//   console.log("3");
//   regex = /\n(.*?)\n\n/gs; // 捕获在 \n 和 \n\n 之间的内容
//   matches = input.match(regex);

//   if (matches && matches.length === matchLength) {
//     return matches.map((match3) => match3.slice(2, -4)); // 去掉最外层的 """
//   }

//   //如果没有，尝试第4次
//   console.log("4");
//   //  regex = /(\d+\.)(.*?)(?=\n\n)/gs; // 捕获在 \d. 和 \n\n 之间的内容
//   regex = /(\d+\.\s+)(.*?)(?=\n|$)/gs; // 捕获以数字和点开始，并在换行符或文本结尾前的内容
//   matches = input.match(regex);

//   if (matches && matches.length === matchLength) {
//     return matches.map((match3) => match3.slice(2, -4).trim()); // 去掉最外层的 """
//   }

//   //如果没有，尝试第5次
//   console.log("4");
//   //  regex = /(\d+\.)(.*?)(?=\n\n)/gs; // 捕获在 \d. 和 \n\n 之间的内容
//   regex = /(\d+\/\d+\s+)(.*?)(?=\n|$)/gs; // 匹配以 "数字/数字" 开头，并在换行符或文本结尾前的内容
//   matches = input.match(regex);

//   if (matches && matches.length === matchLength) {
//     return matches.map((match3) => match3.slice(4).trim()); // 去掉最外层的 """
//   }

//   return [];
// };

export const spliceGPTDescriptions = (input: string): string[] => {
  const matchLength = 6;

  let matches = input.split(/\n\s*\n/)
  let matches1 =matches.filter(item=>!!item.trim()).map(part => part.trim().replace(/^"(.*?)"$/, '$1').trim()); // 去掉首尾的双引号
  console.log('matches**',matches)
  console.log('matches1**',matches1)
  

  if (matches1 && matches1.length === matchLength) {
    return [matches1[1],matches1[3],matches1[5]]
  }

   matches = input.split(/\n/)
   matches1 =matches .filter(item=>!!item.trim()).map(part => part.trim().replace(/^"(.*?)"$/, '$1').trim()); // 去掉首尾的双引号
  console.log('matches**',matches)
  console.log('matches1**',matches1)
  

  if (matches1 && matches1.length === matchLength) {
    return [matches1[1],matches1[3],matches1[5]]
  }

  return spliceGPTDescriptions2(input)
  return [];
};

export const spliceGPTDescriptions2 = (input: string): string[] => {
  // 使用正则表达式匹配段落
  const regex = /\*\s*([a-zA-Z\s]+):\s*/g; // 匹配 *字母：的模式

  // 初始化结果数组
  const result = [];
  
  // 用来存储当前开始的索引
  let lastIndex = 0;

  // 查找所有匹配项
  let match;
  while ((match = regex.exec(input)) !== null) {
      if (lastIndex < match.index) {
          // 提取前一个段落内容
          result.push(input.substring(lastIndex, match.index).trim());
      }
      lastIndex = regex.lastIndex; // 更新最后一个匹配的结束索引
  }

  // 将最后一段内容添加到结果中
  if (lastIndex < input.length) {
      result.push(input.substring(lastIndex).trim());
  }
  
  return result;
}