import { combineReducers } from 'redux';
// import * as authLocalData from '../presist/auth-local-data';
import * as userInfoLocalData from './userinfo-local-data';
import * as appSettingLocalData from './app-setting-data';



const combinedReducer = combineReducers({
  userInfoLocalData: userInfoLocalData.reducer(),
  appSettingLocalData:appSettingLocalData.reducer()
  
});

const actions = {
  userInfoLocalData: userInfoLocalData.actions,
  appSettingLocalData:appSettingLocalData.actions
 
};

const selectors = {
  userInfoLocalData: userInfoLocalData.select,
  appSettingLocalData:appSettingLocalData.select

};

export {
  actions,
  selectors,
  combinedReducer,
  userInfoLocalData,
  appSettingLocalData
  // authLocalData,

};
